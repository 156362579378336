
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import 'react-phone-input-2/lib/style.css';
import countryCodes from './country_codes';
import Uploading from '../accets/upload.gif'
import Sucess from '../accets/progress.gif'

const FormComponent = () => {

  
    // const [formData, setFormData] = useState({
    //     firstName: '',
    //     lastName: '',
    //     contactNumber: '',
    //     email: '',
    //     helpText: ''
    // });
    // const [formErrors, setFormErrors] = useState({});
    // const [isSubmitted, setIsSubmitted] = useState(false);
    // const [isSubmitting, setIsSubmitting] = useState(false);

    // const validate = () => {
    //     let errors = {};
    //     if (!formData.firstName) errors.firstName = "First Name is required";
    //     if (!formData.lastName) errors.lastName = "Last Name is required";
    //     if (!formData.contactNumber) errors.contactNumber = "Contact Number is required";
    //     if (!formData.email) {
    //         errors.email = "Email Address is required";
    //     } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    //         errors.email = "Email Address is invalid";
    //     }
    //     if (!formData.helpText) errors.helpText = "This field is required";
    //     return errors;
    // };

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //         ...formData,
    //         [name]: value,
    //     });
    //     setFormErrors({
    //         ...formErrors,
    //         [name]: '',
    //     });
    // };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const errors = validate();
    //     if (Object.keys(errors).length === 0) {
    //         setIsSubmitting(true);
    //         emailjs.sendForm('service_cyqw8yi', 'template_u1am4c1', e.target, 'UodQS99XhEC_MLYqR')
    //             .then((result) => {
    //                 console.log(result.text);
    //                 setIsSubmitting(false);
    //                 setIsSubmitted(true);
    //                 setFormData({
    //                     firstName: '',
    //                     lastName: '',
    //                     contactNumber: '',
    //                     email: '',
    //                     helpText: ''
    //                 });
    //             }, (error) => {
    //                 console.log(error.text);
    //                 setIsSubmitting(false);
    //             });
    //     } else {
    //         setFormErrors(errors);
    //     }
    // };

    // return (
    //     <form onSubmit={handleSubmit} className="max-w-lg mx-auto p-6 bg-white rounded-lg border-3 shadow-md">
    //         <div className="flex flex-wrap -mx-2">
    //             <div className="w-full md:w-1/2 px-2 mb-4">
    //                 <label className="font-bold text-2xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">
    //                     First Name
    //                 </label>
    //                 <input
    //                     type="text"
    //                     name="firstName"
    //                     value={formData.firstName}
    //                     onChange={handleChange}
    //                     className={`mt-2 block w-full bg-transparent border-b-2 ${formErrors.firstName ? 'border-red-500' : 'border-gray-300'} focus:border-blue-600 outline-none transition duration-300`}
    //                     placeholder="First Name"
    //                     required
    //                 />
    //                 {formErrors.firstName && <p className="text-red-500 text-sm">{formErrors.firstName}</p>}
    //             </div>
    //             <div className="w-full md:w-1/2 px-2 mb-4">
    //                 <label className="font-bold text-2xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">
    //                     Last Name
    //                 </label>
    //                 <input
    //                     type="text"
    //                     name="lastName"
    //                     value={formData.lastName}
    //                     onChange={handleChange}
    //                     className={`mt-2 block w-full bg-transparent border-b-2 ${formErrors.lastName ? 'border-red-500' : 'border-gray-300'} focus:border-blue-600 outline-none transition duration-300`}
    //                     placeholder="Last Name"
    //                     required
    //                 />
    //                 {formErrors.lastName && <p className="text-red-500 text-sm">{formErrors.lastName}</p>}
    //             </div>
    //             <div className="w-full md:w-1/2 px-2 mb-4">
    //                 <label className="font-bold text-2xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">
    //                     Contact Number
    //                 </label>
    //                 <input
    //                     type="text"
    //                     name="contactNumber"
    //                     value={formData.contactNumber}
    //                     onChange={handleChange}
    //                     className={`mt-2 block w-full bg-transparent border-b-2 ${formErrors.contactNumber ? 'border-red-500' : 'border-gray-300'} focus:border-blue-600 outline-none transition duration-300`}
    //                     placeholder="Contact Number"
    //                     required
    //                 />
    //                 {formErrors.contactNumber && <p className="text-red-500 text-sm">{formErrors.contactNumber}</p>}
    //             </div>
    //             <div className="w-full md:w-1/2 px-2 mb-4">
    //                 <label className="font-bold text-2xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">
    //                     Email Address
    //                 </label>
    //                 <input
    //                     type="email"
    //                     name="email"
    //                     value={formData.email}
    //                     onChange={handleChange}
    //                     className={`mt-2 block w-full bg-transparent border-b-2 ${formErrors.email ? 'border-red-500' : 'border-gray-300'} focus:border-blue-600 outline-none transition duration-300`}
    //                     placeholder="Email Address"
    //                     required
    //                 />
    //                 {formErrors.email && <p className="text-red-500 text-sm">{formErrors.email}</p>}
    //             </div>
    //         </div>
    //         <div className="w-full mb-4">
    //             <label className="font-bold text-2xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">
    //                 How can we help you?
    //             </label>
    //             <textarea
    //                 name="helpText"
    //                 value={formData.helpText}
    //                 onChange={handleChange}
    //                 className={`mt-2 block w-full bg-transparent border-b-2 ${formErrors.helpText ? 'border-red-500' : 'border-gray-300'} focus:border-blue-600 outline-none transition duration-300 h-32 resize-none`}
    //                 placeholder="Please describe how we can help you"
    //                 required
    //             />
    //             {formErrors.helpText && <p className="text-red-500 text-sm">{formErrors.helpText}</p>}
    //         </div>
    //         <div className="flex justify-end">
    //             <button
    //                 type="submit"
    //                 className={`w-auto px-4 py-2 bg-gradient-to-r from-purple-400 to-pink-500 text-white font-bold transition duration-300 hover:from-pink-500 hover:to-purple-400 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
    //                 disabled={isSubmitting}
    //             >
    //                 {isSubmitted ? 'Submited' : 'Submit'}
    //             </button>
    //         </div>
    //     </form>
    // );

    const [files, setFiles] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
  
    const handleFileChange = (e) => {
      setFiles([...e.target.files]);
    };
  
    const handleDragOver = (e) => {
      e.preventDefault();
      setIsDragging(true);
    };
  
    const handleDragLeave = () => {
      setIsDragging(false);
    };
  
    const handleDrop = (e) => {
      e.preventDefault();
      setIsDragging(false);
      setFiles([...e.dataTransfer.files]);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      // Handle form submission and file upload logic
      console.log('Form submitted with files:', files);
    };
    

    return (

  
        <form className="contact-form w-full max-w-lg mx-auto mt-8" onSubmit={handleSubmit} >
    
          <div className="mb-4">
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Your name"
              required
              className="w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>
          {/* phonenumber */}

          <div className="mb-4">
          <div className="flex">
        <select
      id="country-code"
      name="country-code"
      required
      className="w-1/4 px-4 py-2 mr-2 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
     >
         {countryCodes.map((country) => (
            <option key={country.code} value={country.code}>
              {country.code} ({country.country})
            </option>
          ))}
        </select>
          <input
      type="tel"
      id="phone-number"
      name="phone-number"
      placeholder="Your phone number"
      required
      className="w-3/4 px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-500"
    />
     </div>
    </div>


          {/* phonenumber */}
          <div className="mb-4">
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your email"
              required
              className="w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>
          <div className="mb-2">
            <textarea
              id="message"
              name="message"
              rows="4"
              placeholder="Your message"
              required
              className="w-full px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            ></textarea>
          </div>

          {/* File Upload Section */}
          <div
            className={`mb-4 p-4 border-2 rounded-lg ${isDragging ? 'border-purple-500' : 'border-gray-300'} transition-colors`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <input
              type="file"
              id="fileUpload"
              multiple
              onChange={handleFileChange}
              className="hidden"
            />
            
            <label
              htmlFor="fileUpload"
              className="block text-center text-gray-500 cursor-pointer"
            >
              {files.length > 0
                ? `Selected files: ${files.map((file) => file.name).join(', ')}`
                : 'Drag and drop files here or click to select files'}
            </label>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="submit-btn w-48 h-10 px-6 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors flex items-center"
            >
             Submit your inqurey
            </button>
          </div>
        </form>
      
            

);

};

export default FormComponent;
















// import React, { useState } from 'react';

// const StepperForm = () => {
//     const [step, setStep] = useState(1);
//     const [formData, setFormData] = useState({
//         firstName: '',
//         lastName: '',
//         contactNumber: '',
//         email: '',
//         trouble: ''
//     });

//     const handleChange = (e) => {
//         setFormData({
//             ...formData,
//             [e.target.name]: e.target.value
//         });
//     };

//     const nextStep = () => {
//         if (step < 4) {
//             setStep(step + 1);
//         }
//     };

//     const prevStep = () => {
//         if (step > 1) {
//             setStep(step - 1);
//         }
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         console.log(formData);
//     };

//     return (
//         <div className="relative max-w-4xl mx-auto p-6">
//             <form onSubmit={handleSubmit}>
//                 {step === 1 && (
//                     <div className="flex space-x-20">
//                         <div className="w-full">
//                             <label className="font-bold text-2xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">First Name</label>
//                             <input
//                                 type="text"
//                                 name="firstName"
//                                 value={formData.firstName}
//                                 onChange={handleChange}
//                                 className="mt-2 block w-full bg-transparent border-transparent focus:border-blue-600 outline-none transition duration-300"
//                                 placeholder="First Name"
//                                 required
//                             />
//                         </div>
//                         <div className="w-full">
//                             <label className="font-bold text-2xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">Last Name</label>
//                             <input
//                                 type="text"
//                                 name="lastName"
//                                 value={formData.lastName}
//                                 onChange={handleChange}
//                                 className="mt-2 block w-full bg-transparent border-transparent focus:border-blue-600 outline-none transition duration-300"
//                                 placeholder="Last Name"
//                                 required
//                             />
//                         </div>
//                     </div>
//                 )}
//                 {step === 2 && (
//                     <div className="flex space-x-20">
//                         <div className="w-full">
//                             <label className="font-bold text-2xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">Email Address</label>
//                             <input
//                                 type="tel"
//                                 name="contactNumber"
//                                 value={formData.contactNumber}
//                                 onChange={handleChange}
//                                 className="mt-2 block w-full bg-transparent border-transparent focus:border-blue-600 outline-none transition duration-300"
//                                 placeholder="Contact Number"
//                                 required
//                             />
//                         </div>
//                         <div className="w-full">
//                             <label className="font-bold text-2xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">Phone Number</label>
//                             <input
//                                 type="email"
//                                 name="email"
//                                 value={formData.email}
//                                 onChange={handleChange}
//                                 className="mt-2 block w-full bg-transparent border-transparent focus:border-blue-600 outline-none transition duration-300"
//                                 placeholder="Email Address"
//                                 required
//                             />
//                         </div>
//                     </div>
//                 )}
//                 {step === 3 && (
//                     <div>
//                         <label className="font-bold text-2xl bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">Describe Your Trouble</label>
//                         <textarea
//                             name="trouble"
//                             value={formData.trouble}
//                             onChange={handleChange}
//                             className="mt-2 bg-transparent border-transparent outline-none transition duration-300 w-full max-w-xl min-h-[5rem]"
//                             placeholder="Describe Your Trouble"
//                             required
//                         />
//                     </div>
//                 )}

//                 <div className="flex justify-start mt-6 space-x-4">
//                     {step > 1 && (
//                         <button
//                             type="button"
//                             onClick={prevStep}
//                             className="px-6 py-2 bg-gray-300 text-gray-700 shadow-sm hover:bg-gray-400 transition duration-300"
//                         >
//                             Back
//                         </button>
//                     )}
//                     {step < 3 && (
//                         <button
//                             type="button"
//                             onClick={nextStep}
//                             className="px-6 py-2 bg-blue-500 text-white shadow-sm hover:bg-[#0f072c] transition duration-300"
//                         >
//                             Next <span className="ml-2">{Math.round((step / 3) * 100)}%</span>
//                         </button>
//                     )}
//                     {step === 3 && (
//                         <button
//                             type="submit"
//                             className="px-6 py-2 bg-green-500 text-white shadow-sm hover:bg-green-600 transition duration-300"
//                         >
//                             Submit <span className="ml-2">{Math.round((step / 3) * 100)}%</span>
//                         </button>
//                     )}
//                 </div>
//             </form>
            
//         </div>
//     );
// };

// export default StepperForm;

