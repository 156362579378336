import React from 'react'
import './css/content.css'


function Content() {
  return (
    <div className="bg-[#F8F9FF] min-h-screen py-8 relative">
      <div className="text-2xl font-bold font-poppins tracking-tight text-pink-500 sm:text-2xl">
        <p className="font-lexend mx-4 mt-8 sm:mx-8 sm:ps-28 py-4">Problem Identification</p>
      </div>
      <div className="mx-4 sm:mx-8 sm:ps-28 marquee-text text-3xl font-inter text-gray-800 hover:text-pink inline-block sm:text-3xl"style={{wordSpacing: -3 ,}}>
        Pinpointing Issues for Effective Solutions
      </div>
      <div className="mx-4 pt-6 sm:mx-8 sm:ps-28 marquee-text text-lg font-poppins text-gray-800 sm:text-xl pb-16 ">
        <div className="w-full sm:w-3/4 whitespace-pre-line">
          <p className="mb-4"style={{wordSpacing: 2.5 ,}}>
            Pinpointing Issues for EffectBuilding tech and digital products can appear straightforward,
            yet ensuring they resonate with a specific business domain and meet market demands requires a deeper
            understanding. Rushing into development without this insight often leads to products that fall short of
            expectations, failing to address customer needs effectively. This approach not only wastes resources but
            also risks damaging reputation and losing market opportunities.
          </p>
         <p style={{wordSpacing: 2 ,}} >
            Therefore, prioritizing comprehensive
            market research and understanding business requirements from the outset is essential for creating successful,
            customer-centric products that deliver real value and foster long-term success.ive Solutions.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Content
