import React from 'react';
import {
    ArrowPathRoundedSquareIcon,
    CogIcon,
    UsersIcon
} from '@heroicons/react/24/outline';
import { Center } from '@chakra-ui/react';

function Servise_answer() {
    const cards = [
        {
            id: 1,
            title: 'Product Development.',
            description: 'Transforming Ideas into Market-Ready Innovations.',
            href: '/Product_development',
            icon: ArrowPathRoundedSquareIcon
        },
        {
            id: 2,
            title: 'Customer Engagement.',
            description: 'Connecting and Growing Together.',
            icon: CogIcon,
            href: '/customer_engagement'
        },
        {
            id: 3,
            title: 'Operational Teams.',
            description: 'Efficiency in Action, Excellence in Execution.',
            icon: UsersIcon,
            title2: 'Coming soon'
        },
    ];

    return (
        <div className="bg-[#0f072c] min-h-screen py-8 relative">
            {/* Watermark */}
            {/* <div className="py-16 mr-16 hidden sm:block watermark absolute right-0 top-1/2 transform -translate-y-1/2 text-white opacity-10 text-8xl font-bold">
                Stage 02
            </div>
            <div className="block sm:hidden absolute top-4 right-4 text-pink-500 opacity-20 text-3xl font-bold">
                Stage 02
            </div> */}
            <div className="py-8 text-3xl font-bold font-poppins tracking-tight text-pink-500 sm:text-2xl">
                <p className="font-lexend mx-4 mt-8 sm:mx-8 sm:ps-28 py-2">Our Feedback</p>
            </div>
            <div className="mx-4 sm:mx-8 sm:ps-28  max-w-5xl marquee-text text-3xl font-inset text-white hover:text-pink inline-block sm:text-3xl" style={{ wordSpacing: 0, }}>
                Our dedicated services enable you to confidently advance through your product journey, choosing the
                precise solutions required at each step.
            </div>
            <div className="mx-4 pt-8 sm:mx-8 sm:ps-24 marquee-text text-lg font-poppins text-gray-800 sm:text-xl pb-16">
                <div className="carousel-container overflow-hidden relative w-full h-full">

                    <div className="flex transition-transform duration-300 ease-in-out overflow-x-auto sm:overflow-hidden">
                        {cards.map((card) => (
                            <div data-aos="flip-left">
                                <div
                                    key={card.id}
                                    className="card bg-white shadow-lg rounded-lg p-6 m-4 w-80 flex-none relative overflow-hidden"
                                    style={{ height: '26rem' }}
                                >
                                    {/* Background image and opacity effect using a pseudo-element */}
                                    <div
                                        className="absolute inset-0 bg-cover bg-center"
                                        style={{
                                            backgroundImage: `url(${process.env.PUBLIC_URL}/../bg.jpg)`,
                                            opacity: 0.1,
                                        }}
                                    ></div>
                                    <div className="relative z-10"> {/* Ensure the content is above the background */}
                                        <div className="pt-18 flex h-16 w-16 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white md:pt-18 md:flex md:h-16 md:w-16">
                                            {card.icon && <card.icon className="text-gray-600 group-hover:text-indigo-600 " aria-hidden="true" />}
                                        </div>
                                        <div className="pt-8 mt-18n font-poppins text-3xl sm:text-stroke text-black">
                                            {card.title}
                                        </div>

                                        <h2 className="pb-16 pt-8 font-lexend text-xl mb-22 sm:text-lg flex items-center">
                                            {card.description}
                                        </h2>

                                        <div className="mt-18 py-2 px-4">
                                            {card.href && (
                                                <a
                                                    href={card.href}
                                                    className="text-sky-400 font-poppins link-with-arrow font-bold after:content-['_↗']"
                                                >
                                                    Read more
                                                </a>
                                            )}

                                            <a
                                                
                                                className="text-sky-400 font-poppins link-with-arrow font-bold after:content-['_↗']"
                                            >
                                               {card.title2}
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Servise_answer;
