import React from 'react'
import Navbar from '../components/navbar'
import Getintouch from '../components/getin_tuch'
import Footer from '../components/footer'
import Meet_product from './meeting api/meet_product'
import ProjectManagement from '../accets/product.jpg'
import Solution from '../accets/solution.jpg'
import vision from '../accets/target.png'
import Trade from '../accets/trade.png'
import Share from '../accets/share.png'
import Skill from '../accets/skill.png'
import Analysis from '../accets/analysis.png'
import Advise from '../accets/guide.png'

function Product_management() {
    return (
        <>
            <Navbar />
            {/* Section1 */}
            <div className="pt-18 pb-38  h-screen flex flex-col justify-center items-center bg-[#0f072c]">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-400 via-pink-500 to-purple-400 opacity-20 blur-2xl rounded-full w-[200px] h-[200px] md:w-[500px] md:h-[500px] mx-auto"></div>
                <div
                    className="relative text-center"
                    data-aos="fade-up" // Animation type
                    data-aos-duration="1000" // Duration of the animation
                    data-aos-once="true" // Ensures animation occurs only once
                >
                    <p className="pb-8 text-sm font-bold font-lexend tracking-tight text-pink-500 sm:text-2xl">
                        Altitude - driven project management
                    </p>
                    <span className="text-3xl sm:text-6xl font-lucida font-bold text-white ">
                        Gain a competitve
                        edge with<br></br> <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 transition-all duration-500'>
                            project management
                        </span>
                    </span>
                    <p className="mt-8 text-lg sm:text-xl text-white max-w-sm sm:max-w-3xl mx-auto">
                        Efficiently planning, organizing, and executing projects to achieve business goals,
                        milestones, and deliverables within time and budget constraints.
                    </p>
                </div>
            </div>
            {/* Section */}
            <div className="bg-[#F8F9FF] min-h-screen py-12 relative">
                <div className="marquee-text text-center text-2xl sm:text-3xl font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                    <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >Facing challenges with a <br></br></span>
                    <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >unified product vision in your team?</span>
                </div>
                <div className="flex justify-center items-center">
                    <div className="marquee-text text-center text-xl sm:text-2xl font-lucida font-bold">
                        <p className="py-4 font-lexend w-3/5 mx-auto text-gray-500">
                            If fragmented strategies and disjointed innovation are issues, see how Beta Launch's 'Digital-Product-Managers-as-a-Service' can align your team and create a unified path to success.
                        </p>
                    </div>
                </div>
                <div className="max-w-screen-lg mx-auto mt-16 p-8">
                    <div className="flex items-center">
                        <div className="w-2/3 mr-8">
                            <div className="bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r uppercase font-semibold font-lucida ">Major Roadblocks</div>
                            <h3 className="text-3xl font-bold mt-2 ">Are These Challenges Impacting Your Digital Product Teams?</h3>
                            <div className="my-4"></div>
                            <p className="text-lg text-gray-700 font-lucida">
                                In the fast-evolving world of digital product development, challenges such as disjointed efforts and vague product strategies can hinder your startup's path to success.
                            </p>
                        </div>
                        <div className="w-1/3">
                            <img src={ProjectManagement} alt="Illustrative Image" className="rounded-lg shadow-md" />
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-[#0f072c] py-16 relative'>
                <div className="flex flex-col md:flex-row gap-8 justify-center text-center max-w-6xl mx-auto">
                    <div className="mr-8 ml-8 md:max-w-xs mx-auto">
                        <div className="my-4 text-left text-gray-400"><img src={vision} /></div>
                        <h5 className="font-lucida font-semibold text-xl text-white text-left">
                            Facing Challenges in Unifying Your Product Vision?
                        </h5>
                        <div className="my-2"></div>
                        <div className="text-lg text-gray-600 font-lucida leading-tight mt-9 text-left">
                            Fostering a unified product vision and ensuring alignment within your product team can be challenging.
                        </div>
                    </div>
                    <div className="mr-8 ml-8 md:max-w-xs mx-auto">
                        <div className="my-4 text-left text-gray-400"><img src={Trade} /></div>
                        <h5 className="font-lucida font-semibold text-xl text-white text-left">
                            Finding the Demand for Product Management Expertise Overwhelming?
                        </h5>
                        <div className="my-2"></div>
                        <div className="text-lg text-gray-600 font-lucida leading-tight text-left">
                            The demand for product management expertise can be overwhelming, and hiring full-time staff may not be cost-effective.
                        </div>
                    </div>
                    <div className="mr-8 ml-8 md:max-w-xs mx-auto">
                        <div className="my-4 text-left text-gray-400"><img src={Share} /></div>
                        <div className="my-4"></div>
                        <h5 className="font-lucida font-semibold text-xl text-white text-left">
                            Experiencing Fragmentation in Your Product Development Process?
                        </h5>
                        <div className="my-2"></div>
                        <div className="text-lg text-gray-600 font-lucida leading-tight text-gray-500 text-left">
                            Fragmented product development efforts can lead to poor outcomes and innovation roadblocks.
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-[#F8F9FF] py-4 relative'>
                <div className=" max-w-screen-lg mx-auto mt-16 p-8">
                    <div className="flex items-center">
                        <div className="w-2/3 mr-8">
                            <div className="bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r uppercase font-semibold font-lucida ">What We Offer</div>
                            <h3 className="text-3xl font-bold mt-2 ">On-Demand Product Managers</h3>
                            <div className="my-4"></div>
                            <p className="text-lg text-gray-700 font-lucida">
                                Our 'On-Demand Product Managers' service enhances your product journey by providing
                                experienced Digital Product Managers who align your vision with flawless execution,
                                ensuring a smooth, market-focused process.
                            </p>
                        </div>
                        <div className="w-1/3">
                            <img src={Solution} alt="Illustrative Image" className="rounded-lg shadow-md" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='bg-[#0f072c] py-16 relative'>
                <div className="flex flex-col md:flex-row gap-8 justify-center text-center max-w-6xl mx-auto">
                    <div className="mr-8 ml-8 md:max-w-xs mx-auto">
                        <div className="my-1 text-left text-gray-400"><img src={Skill} /></div>
                        <h5 className="font-lucida font-semibold text-xl text-white text-left">
                            Skilled Digital Product Managers
                        </h5>
                        <div className="my-6"></div>
                        <div className="text-lg text-gray-600 font-lucida leading-tight mt-9 text-left">
                            Engage with experienced Digital Product Managers who are committed to the success of your project                        </div>
                    </div>
                    <div className="mr-8 ml-8 md:max-w-xs mx-auto">
                        <div className="my-2 text-left text-gray-400"><img src={Analysis} /></div>
                        <h5 className="font-lucida font-semibold text-xl text-white text-left">
                            All-Encompassing Market and Segment Analysis
                        </h5>
                        <div className="my-4"></div>
                        <div className="text-lg text-gray-600 font-lucida leading-tight text-left">
                            We begin by performing in-depth market research and targeting the optimal
                            customer segment to align your product with customer needs.
                        </div>
                    </div>
                    <div className="mr-8 ml-8 md:max-w-xs mx-auto">
                        <div className="my-2 text-left text-gray-400"><img src={Advise} /></div>
                        <div className="my-1"></div>
                        <h5 className="font-lucida font-semibold text-xl text-white text-left">
                            Product Strategy Advisory
                        </h5>
                        <div className="my-8"></div>
                        <div className="text-lg text-gray-600 font-lucida leading-tight text-gray-500 text-left">
                            Leverage expert guidance, strategic planning, and best practices
                            to create innovative products and achieve successful launches.
                        </div>
                    </div>
                </div>
            </div>
            {/* end */}
            {/* end */}
            <div className='pb-16 pt-16 bg-[#F8F9FF] min-h-screen relative'>
                <Meet_product />
            </div>
            <Getintouch />
            <Footer />
        </>
    )
}

export default Product_management