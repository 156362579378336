import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import Getintouch from '../components/getin_tuch'
import Inherit from './timeline'
import Meet_software from './meeting api/meet_software'

function Custom_software_development() {
    return (
        <>
            <Navbar />
            <div className="pt-18 pb-38 h-screen flex flex-col justify-center items-center bg-[#0f072c]">
                <div className="absolute inset-0 bg-gradient-to-r from-purple-400 via-pink-500 to-purple-400 opacity-20 blur-2xl rounded-full w-[200px] h-[200px] md:w-[500px] md:h-[500px] mx-auto"></div>
                <div
                    className="relative text-center"
                    data-aos="fade-up" // Animation type
                    data-aos-duration="1000" // Duration of the animation
                    data-aos-once="true" // Ensures animation occurs only once
                >
                    <p className="pb-8 text-sm font-bold font-lexend tracking-tight text-pink-500 sm:text-2xl">
                        Altitude - driven software development
                    </p>
                    <span className="text-3xl sm:text-6xl font-lucida font-bold text-white ">
                        Gain a competitve
                        edge with<br></br> <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r hover:from-pink-500 hover:to-yellow-500 transition-all duration-500'>
                            custom software
                        </span>
                    </span>
                    <p className="mt-8 text-lg sm:text-xl text-white max-w-sm sm:max-w-3xl mx-auto">
                        When off-the-shelf software won't cut it, you need a solution that's tailor-made to help you reach your business goals.
                    </p>
                </div>
            </div>
            {/* section2 */}
            <div className="bg-[#F8F9FF] min-h-screen py-12 relative">
                {/* headline */}
                <div className="marquee-text text-center text-2xl sm:text-3xl font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                    <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >With the right functionality,<br></br></span>
                    <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >you can solve virtually any business problem</span>
                </div>
                <div className=" marquee-text text-center text-xl sm:text-2xl font-lucida font-bold text-gray-500">
                    <p className="py-4 font-lexend">Custom software can help if you.</p>
                </div>
                {/* headline */}
                {/* devider */}
                <div class="relative py-4">
                    <div class="flex items-center justify-center">
                        <div class="h-0.5 bg-gradient-to-r from-purple-400 to-transparent w-1/4"></div>

                        <div class="h-0.5 bg-gradient-to-l from-purple-400 to-transparent w-1/4"></div>
                    </div>
                </div>
                {/* devider */}
                {/* checklist */}
                <div className="mx-4 sm:mx-8 sm:ps-28 marquee-text text-center text-lg font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                    <div className="grid grid-cols-1 md:grid-cols-2 ml-16 mt-8" >
                        <ul className="space-y-1 text-gray-500 list-inside dark:text-gray-400" data-aos="fade-down">
                            <li class="flex items-center">

                                <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Got an idea? Let’s create something new.
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Boost productivity without extra hires.
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Struggling with disconnected programs.
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Need to streamline complicated workflows.
                            </li>
                        </ul>
                        <ul className="space-y-1 text-gray-500 list-inside dark:text-gray-400" data-aos="fade-down">
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Overwhelmed and missing details?
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Outgrown your system? Rebuild now
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Overwhelmed and missing details?
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 me-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Streamline team and service management.
                            </li>
                        </ul>
                    </div>
                </div>
                {/* checklist */}
                {/* section2 end */}
                {/* section3 */}
                {/* headline */}

                <div data-aos="fade-down" className='<div class="md:flex">'>
                    <div className="md mx-4 sm:mx-8 sm:ps-28 mt-16 marquee-text text-left text-2xl sm:text-3xl sm:text-left font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                        <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >Custom, scalable solutions <br></br></span>
                        <span className='bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r ' >for your needs.</span>
                    </div>
                    <div className="mx-4 sm:mx-8 sm:ps-28 marquee-text text-left text-xl w-3/6 sm:text-2xl font-lucida font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text hover:bg-gradient-to-r">
                        <p className="py-4 font-lexend text-gray-500">We create software with reliable, established frameworks from Facebook and Google, ensuring your system grows with you.</p>
                    </div>
                </div>
                {/* headline */}
                <Inherit />
                <div className='pb-8 pt-8 bg-[#F8F9FF] min-h-screen relative'>
               <Meet_software/>
               </div>
            </div>
            <Getintouch />
            <Footer />
        </>
    )
}

export default Custom_software_development




//mobiole_: mx-4 sm:mx-8 sm:ps-28